
import { defineComponent } from 'vue'
import { BillDto } from '@/api/bill'
import { billRecord } from '@/api/insure-deduction'

export default defineComponent({
    name: 'InsureBill',
    data() {
        return {
            pageSize: 10, // 每页显示十条
            pageNum: 1, // 页数为1
            total: 0, // 总的为0
            tableData: [] as BillDto[] // 如同BillDto的数据
        }
    },
    created() { // 创建
        this.getBillRecord()
    },
    methods: {
        async getBillRecord() {
            const result = await billRecord({
                pageNum: this.pageNum,
                pageSize: this.pageSize
            })
            console.log(result, 'result')
            this.total = result.totalCount
            this.tableData = result.list
        },
        handleSizeChange() {
            this.pageNum = 1
            this.getBillRecord()
        }
    }
})
