
import { defineComponent } from 'vue'
import tabCapsule from '@/components/tab-capsule.vue'
import {
    getBalance,
    getPayTrend,
    getPeopleCount,
    getInsuranceTrend,
    getContractTrend,
    getInsuranceRisk
} from '@/api/home'
import * as echarts from 'echarts'
import { dateFormat } from '@/utils/util'
import store from '@/store'
import user from '@/utils/user'
import trend from './home/components/trend.vue'
import alipayRechargeDialog from '@/components/alipay-recharge-dialog/alipay-recharge-dialog.vue'
import wechatRechargeDialog from '@/components/wechat-recharge-dialog/wechat-recharge-dialog.vue'
import { AccountProductStatusDto, CompanyItemDto, getAccountProductStatusApi, replaceCompany, getCompanyListApi, queryRechargeAndSync } from '@/api/alipay-recharge'
import midAutumn from '@/components/dialog/mid-autumn.vue'
import global from '@/utils/global'

const now = new Date().getTime()
const end = new Date('2025-02-04 23:59:59').getTime()
const start = new Date('2025-01-20 00:00:00').getTime()

export default defineComponent({
    name: 'Home',
    components: {
        tabCapsule,
        alipayRechargeDialog,
        wechatRechargeDialog,
        // seatNotice,
        trend,
        midAutumn
    },
    data() {
        return {
            companyList: [] as CompanyItemDto[],
            accountProductStatus: {} as AccountProductStatusDto,
            isShow: false,
            isShowwechat: false,
            balances: {
                eleSignOverAmount: 0,
                payBalance: 0,
                wesureBalance: 0,
                canUseBalance: 0
            },
            trendBasic: {
                payAvailableTotalMoney: 0,
                payTotalMoney: 0,
                payOrderCount: 0,
                payStopTotalMoney: 0,
                payReceiveTotalMoney: 0
            },
            addressPeopleCount: 0,
            unclaimedCount: 0,
            payMonth: '',
            insuranceTab: 0 as any,
            contractTab: 0,
            isOpenWesure: false,
            isOpenEleSign: false,
            isOpenPaySalary: false,
            $store: store,
            riskInfo: {} as any,
            claimRate: 0,
            insureType: '0',
            recordNum: 'insure_type_rrb',
            showSeatNotice: !localStorage.getItem('showSeatDialog'),
            advanceUnclaimedCount: 0,
            showFestival: !localStorage.getItem('2025newyearPop') && (now < end) && (now > start)
        }
    },
    async mounted() {
        await user.waitLogin()
        console.log('user.waitLogin')
        console.log(user.isOpenEleSign)
        this.payMonth = dateFormat('YYYY-mm', new Date())
        // if (user.isOpenEleSign) {
        //     this.isOpenEleSign = user.isOpenEleSign
        //     this.onContractTab(0)
        // }
        if (user.isOpenWesure) {
            this.isOpenWesure = user.isOpenWesure
            this.onInsuranceTab(0)
            this.getInsuranceRiskInfo()
        }
        if (user.isOpenPaySalary) {
            this.isOpenPaySalary = user.isOpenPaySalary
            this.onPayMonth()
        }
    },
    async created() {
        queryRechargeAndSync()
        let res = await getAccountProductStatusApi()
        console.log(res, 'ufgsdyuygfs')
        let commonlyList = await getCompanyListApi({ userId: user.userId })
        console.log(commonlyList, 'commonlyList')
        this.companyList = commonlyList as CompanyItemDto[]
        this.accountProductStatus = res
        getBalance().then(res => {
            this.balances = res
        })
        getPeopleCount().then(res => {
            this.addressPeopleCount = res.addressPeopleCount
            this.unclaimedCount = res.unclaimedCount
            this.advanceUnclaimedCount = res.advanceUnclaimedCount
        })
    },
    methods: {
        /**
         * 切换公司
         * @param username
         * @param companyId
         */
        async onChangeCompany(companyId: string | number) {
            await user.switchLogin({
                username: user.phone,
                companyId: companyId
            })
            window.location.reload()
            // let res: any = await replaceCompany({ username: user.phone, companyId: companyId })
            // console.log(res)
            // this.$message.success('切换公司成功。')
            // global.token = res.token
            // sessionStorage.token = res.token
            // user.getUserInfo()

        },
        /**
         * 微信充值
         */
        onWechatRecharge() {
            this.isShowwechat = true
        },
        // 去详情
        onDetail() {
            this.$router.push({ path: 'account-details' })
        },
        // 在保人数切换Type
        onRecordNum(str: string) {
            console.log(str)
            this.recordNum = str
            this.onInsuranceTab(this.insuranceTab)
        },
        // 日日保仪表盘数据
        async getInsuranceRiskInfo() {
            const result = await getInsuranceRisk({
                coName: user.companyName
            })
            const riskData = await this.getChartsRisk()
            riskData.series[0].data[0].value = 100 - (100 * (Number(((result.finalMoney / result.totalPrice) * 100).toFixed(2)) >= 140 ? 140 : Number(((result.finalMoney / result.totalPrice) * 100).toFixed(2))) / 140)
            riskData.series[1].data[0].value = (98 - (100 * (Number(((result.finalMoney / result.totalPrice) * 100).toFixed(2)) >= 140 ? 140 : Number(((result.finalMoney / result.totalPrice) * 100).toFixed(2))) / 140)) === 98 ? 100 : (98 - (100 * (Number(((result.finalMoney / result.totalPrice) * 100).toFixed(2)) >= 140 ? 140 : Number(((result.finalMoney / result.totalPrice) * 100).toFixed(2))) / 140))
            this.riskInfo = result
            await this.getDashboardRisk(riskData)
            console.log(riskData.series[0].data[0].value, riskData.series[0].data[0].value, '计算后的内容')
        },
        async onPayMonth() {
            echarts.dispose(document.getElementById('trend') as HTMLElement)
            const result = await getPayTrend({
                queryMonth: this.payMonth
            })
            console.log(result, 'resultresultresult')
            const {
                payAvailableTotalMoney,
                payTotalMoney,
                payOrderCount,
                payStopTotalMoney,
                payReceiveTotalMoney
            } = result
            this.trendBasic = {
                payAvailableTotalMoney,
                payTotalMoney,
                payOrderCount,
                payStopTotalMoney,
                payReceiveTotalMoney
            }
            const option = this.getChartsTemplete()
            const charts = echarts.init(
                document.getElementById('trend') as HTMLElement
            )
            option.grid.y = 60
            option.xAxis.data = result.list.map(
                item => item.date.split(' ')[1] || item.date
            )
            option.series[0].name = '发薪'
            option.series[0].data = result.list.map(item => item.payTotalMoney)
            option.series.push({
                name: '领薪',
                type: 'line',
                smooth: true,
                data: result.list.map(item => item.payReceiveTotalMoney)
            })
            charts.setOption(option)
        },
        async onContractTab(tab: 0 | 1 | 2) {
            echarts.dispose(document.getElementById('contract') as HTMLElement)
            this.contractTab = tab
            // this.contractData[tab] = 123
            const result = await getContractTrend({
                queryRange: ['DAY', 'WEEK', 'MONTH'][tab] as
                    | 'DAY'
                    | 'WEEK'
                    | 'MONTH'
            })
            const option = this.getChartsTemplete()
            const charts = echarts.init(
                document.getElementById('contract') as HTMLElement
            )
            option.title.show = false
            option.legend.show = false
            option.xAxis.data = result.list.map(
                item => item.date.split(' ')[1] || item.date
            )
            option.series[0].data = result.list.map(item => item.signedCount)
            option.series[0].name = '共签署人数'
            charts.setOption(option)
        },
        async onInsuranceTab(tab: 0 | 1 | 2) {
            echarts.dispose(document.getElementById('insurance') as HTMLElement)
            this.insuranceTab = tab
            // this.contractData[tab] = 123
            const result = await getInsuranceTrend({
                insurCompany: this.recordNum,
                queryRange: ['DAY', 'WEEK', 'MONTH'][tab] as
                    | 'DAY'
                    | 'WEEK'
                    | 'MONTH'
            })
            const option = this.getChartsTemplete()
            const charts = echarts.init(
                document.getElementById('insurance') as HTMLElement
            )
            option.title.show = false
            option.legend.show = false
            option.xAxis.data = result.list.map(
                item => item.date.split(' ')[1] || item.date
            )
            option.series[0].data = result.list.map(item => item.wesureCount)
            option.series[0].name = '投保人数'
            charts.setOption(option)
        },
        getChartsTemplete() {
            return {
                title: {
                    text: '发薪及领薪趋势图',
                    top: 10,
                    left: 10,
                    show: true
                },
                backgroundColor: '#F7F8FC',
                tooltip: {
                    show: true,
                    trigger: 'axis'
                },
                grid: {
                    x: 50,
                    y: 50,
                    x2: 50,
                    y2: 50
                },
                legend: {
                    right: 70,
                    top: 10,
                    show: true
                },
                color: ['#4D7CFE', '#67C23A'],
                xAxis: {
                    data: [
                        'Shirts',
                        'Cardigans',
                        'Chiffons',
                        'Pants',
                        'Heels',
                        'Socks'
                    ],
                    boundaryGap: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    }
                },
                yAxis: {
                    minInterval: 1,
                    boundaryGap: ['0%', '10%']
                },
                series: [
                    {
                        name: '发薪',
                        type: 'line',
                        smooth: true,
                        data: [5, 20, 36, 10, 10, 20]
                    }
                ]
            }
        },
        async getDashboardRisk(riskData: any) {
            const option = await riskData
            const charts = echarts.init(document.getElementById('risk') as HTMLElement)
            option && charts.setOption(option)
            console.log('optionoption')
        },
        // 仪表盘数据
        getChartsRisk() {
            return {
                series: [
                    {
                        type: 'gauge',
                        center: ['50%', '90%'],
                        startAngle: 180,
                        endAngle: 0,
                        radius: '130%',
                        min: 0,
                        max: 100,
                        splitNumber: 20,
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#FF7E68' // 0% 处的颜色
                                }, {
                                    offset: 0.25, color: '#FF9444' // 100% 处的颜色
                                }, {
                                    offset: 0.5, color: '#FFC53D' // 100% 处的颜色
                                }, {
                                    offset: 0.75, color: '#B0ED00' // 100% 处的颜色
                                }, {
                                    offset: 1, color: '#00D679' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        progress: {
                            show: true,
                            width: 60
                        },
                        pointer: {
                            // icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                            show: false,
                            length: '130',
                            offsetCenter: [0, -235],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 35,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                        {
                                            offset: 0.3,
                                            color: 'rgba(255, 129, 114, 0.1)'
                                        },
                                        {
                                            offset: 0.7,
                                            color: 'rgba(255, 197, 61, 0.1)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(115, 222, 179, 0.1)'
                                        }
                                    ])
                                    ]
                                ]
                            }
                        },
                        axisTick: {
                            distance: -50,
                            splitNumber: 5,
                            lineStyle: {
                                width: 4,
                                height: 3,
                                color: '#FFF3E1'
                            }
                        },
                        splitLine: {
                            distance: -45,
                            length: 14,
                            show: false,
                            lineStyle: {
                                width: 3,
                                height: 2,
                                color: '#FFF3E1'
                            }
                        },
                        axisLabel: {
                            distance: -30,
                            color: '#999',
                            fontSize: 20,
                            show: true,
                            formatter: function (value: any) {
                                if (value === 0) {
                                    return ['{a|极差}'].join('\n')
                                } else if (value === 20) {
                                    return ['{b|较差}'].join('\n')
                                } else if (value === 50) {
                                    return ['{c|一般}'].join('\n')
                                } else if (value === 80) {
                                    return ['{d|不错}'].join('\n')
                                } else if (value === 100) {
                                    return ['{e|优秀}'].join('\n')
                                }
                            },
                            rich: {
                                a: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#FF7E68',
                                    align: 'center',
                                    margin: 100
                                },
                                b: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#FF9444',
                                    align: 'center'
                                },
                                c: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#FFC53D',
                                    align: 'center'
                                },
                                d: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#B0ED00',
                                    align: 'center'
                                },
                                e: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#00D679',
                                    align: 'center',
                                    margin: 100
                                }
                            }
                        },

                        anchor: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            width: '100%',
                            height: '50%',
                            lineHeight: 60,
                            borderRadius: 8,
                            offsetCenter: [0, '-40%'],
                            fontSize: 60,
                            fontWeight: 'bolder',
                            formatter: function (value: any) {
                                if (value <= 0) {
                                    return ['{a|}', '{b|}', '{a|极差}', '{b|综合评价}'].join('\n')
                                } else if (value > 0 && value <= 25) {
                                    return ['{a|}', '{b|}', '{a|较差}', '{b|综合评价}'].join('\n')
                                } else if (value > 25 && value <= 50) {
                                    return ['{a|}', '{b|}', '{a|一般}', '{b|综合评价}'].join('\n')
                                } else if (value > 50 && value <= 75) {
                                    return ['{a|}', '{b|}', '{a|不错}', '{b|综合评价}'].join('\n')
                                } else if (value > 75 && value <= 100) {
                                    return ['{a|}', '{b|}', '{a|优秀}', '{b|综合评价}'].join('\n')
                                }
                            },
                            rich: {
                                a: {
                                    fontSize: 30,
                                    fontWeight: 600,
                                    fontFamily: 'Arial',
                                    lineHeight: 60,
                                    margin: [250, 0, 0, 0],
                                    color: '#202536',
                                    align: 'center'
                                },
                                b: {
                                    fontSize: 18,
                                    fontWeight: 400,
                                    fontFamily: 'Arial',
                                    lineHeight: -100,
                                    color: '#9497B1',
                                    align: 'center'
                                }
                            },
                            backgroundColor: {
                                width: '1%',
                                height: '1%',
                                image: 'https://s1.ax1x.com/2022/11/10/z9u9w4.png'
                            }
                        },
                        data: [
                            {
                                value: 81
                            }
                        ]
                    },
                    {
                        type: 'gauge',
                        center: ['50%', '90%'],
                        startAngle: 180,
                        radius: '113%',
                        animation: false,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        itemStyle: {
                            color: '#fff'
                        },
                        progress: {
                            show: true,
                            width: 45
                        },
                        pointer: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        detail: {
                            show: false
                        },
                        data: [
                            {
                                value: 80
                            }
                        ]
                    }
                ]
            }
        }
    }
})
