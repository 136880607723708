
import { defineComponent } from 'vue'
import { BillDto } from '@/api/bill'
import { longBillRecord, periodRechargeListExport } from '@/api/long-insure'
import { QueryPageDto } from '@/api/dto'
import store from '@/store'
import axios from 'axios'
import { downLoadFile } from '@/utils/util'

export default defineComponent({
    name: 'LontInsureBill',
    data() {
        return {
            datearr: [],
            pageSize: 10, // 每页显示十条
            pageNum: 1, // 页数为1
            total: 0, // 总的为0
            tableData: [] as BillDto[] // 如同BillDto的数据
        }
    },
    created() { // 创建
        this.getBongBillRecord()
    },
    methods: {
        async onExport() {
            console.log('导出')
            let result = await periodRechargeListExport({
                startTime: this.datearr?.[0] ? this.datearr?.[0] : '',
                endTime: this.datearr?.[1] ? this.datearr?.[1] : ''
            })
            console.log(result, 'sfgadhuyhusgd')
            if (!result.fileUrl && !result.exportStatus) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        },
        onReset() {
            this.datearr = []
            this.search()
        },
        async search() {
            this.pageNum = 1
            await this.getBongBillRecord()
        },
        async getBongBillRecord() {
            const result = await longBillRecord({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                beginPayDate: this.datearr?.[0] ? this.datearr?.[0] + ' 00:00:00' : '',
                endPayDate: this.datearr?.[1] ? this.datearr?.[1] + ' 00:59:59' : ''
            } as QueryPageDto)
            console.log(result, 'result')
            this.total = result.totalCount
            this.tableData = result.list
        },
        handleSizeChange() {
            this.pageNum = 1
            this.getBongBillRecord()
        }
    }
})
