<template>
    <div v-if="showDialog" class="festival-container">
        <div class="inner-box">
            <div class="close-box" @click="handleClose">
                <img src="../../assets/closetwo.png" alt="">
            </div>
            <div class="festival-box" @click="handleClose">
<!--                <div class="festival-bg"></div>-->
                <div class="festivel-content">
                    <div style="width: 100%;box-sizing: border-box;overflow-y: scroll;height: 370px;">
                        <div class="lightblack">
                            尊敬的客户，您好!受中国人民银行(简称“央行”)支付系统影响，<strong>“公对公充值”</strong>将在2025春节期间关闭
                        </div>
                        <div class="lightblack">
                            <strong class="mr-10">关闭时间:
                                <text class="yellow">1月27号 16:30开始</text>
                            </strong>
                            <strong class="mr-10">恢复正常:
                                <text class="yellow">2月5号0点</text>
                            </strong>
                            <span>其余时间与常规状态一致</span>
                        </div>
                        <div class="btn-bg font-bold">
                            关于发薪
                        </div>
                        <div class="mt-6">请提前充值做好工资备款，避免中途账户余额不足。“公对公充值”关闭时间段，法人可以在小程序中使用管理员个人充值。
                            <strong>请合理安排发薪专户的转账充值，以免影响假期使用</strong>
                        </div>
                        <div class="btn-bg font-bold">
                            关于保险
                        </div>
                        <div class="flex mt-6 mb-6">
                            <div class="order flex-no-shrink">1</div>
                            <div>系统正常操作加减保，日日保投保时间不受影响</div>
                        </div>
                        <div class="flex mb-6">
                            <div class="order flex-no-shrink">2</div>
                            <div>
                                为避免影响您的加减保操作，建议您假期前可以多充值部分保费(用不完可以退回)，保证账户余额充足。放假期间服务群将暂停发送余额不足和断保通知。假期充值，微信正常充值；对公充值(18:00之前)，财务收到款会正常录入
                            </div>
                        </div>
                        <div class="flex mb-6">
                            <div class="order flex-no-shrink">3</div>
                            <div>
                                系统余额不足会自动断保，断保后完成充值需要手动操作人员续保，续保成功后会有一天脱保空档期，请知悉。
                            </div>
                        </div>
                        <div class="flex mb-6">
                            <div class="order flex-no-shrink">4</div>
                            <div>在线报案功能不受影响，系统都可操作(24小时之内系统操作在线报案)。上班之后理赔老师会第一时间受理。当日案(投保24
                                小时内发生的)和涉及有疑义或骨折案件，<strong>请及时保存监控视频，</strong>以免影响理赔。
                            </div>
                        </div>
                        <div class="yellow">注意:如有其他问题咨询，请联系您的业务经理或服务群内沟通，都会第一时间给您处理。
                        </div>
                        <div class="yellow bold">紧急联系人：保险 张飞15250218222 <span>发薪 李小芳18260217181</span>
                        </div>
                    </div>
                    <div class="hint">祥蛇贺岁，福满乾坤。值此蛇年佳节，愿贵司业务节节高升，再创新的辉煌！</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDialog: false
        }
    },
    watch: {
        show: {
            handler(newval) {
                this.showDialog = newval
            },
            immediate: true
        },
        showDialog(newval) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        handleClose() {
            localStorage.setItem('2025newyearPop', true)
            this.showDialog = false
        }
    }
}
</script>
<style lang="stylus" scoped>
.festival-container
    position fixed
    left 0
    top 0
    width 100vw
    height 100vh
    overflow scroll
    background rgba(0, 0, 0, 0.7)
    z-index 100
    display flex
    align-items center
    justify-content center

.festival-box
    width 880px
    height 600px
    background url('../../assets/festival/bg-NationalDayNotice.png') no-repeat
    //background radial-gradient(103% 25% at 8% 0%, #F34B1F 0%, rgba(243, 75, 31, 0) 100%), linear-gradient(180deg, #D31213 0%, #CB0B0C 82%, #9B0100 100%)
    background-size 100% 100%
    border-radius 20px
    color #000
    margin-top 10px
    padding-top 160px
    padding-bottom 0
    box-sizing border-box

.festival-bg
    width 480px
    height 149px
    position absolute
    left 50%
    transform translateX(-50%)
    top -75px
    z-index 100

.festivel-content
    font-size 14px
    border-radius 16px
    padding 0 82px
    box-sizing border-box
    height 390px;

.lightblack
    color #3D3D3D

.yellow
    color #E20709

.btn-bg
    display inline-block
    height 32px
    color #E20709
    line-height 32px
    padding 0 15px 0 15px
    font-weight bold
    background-image url("../../assets/festival/btnbg.png")
    background-size 100% 100%
    background-repeat no-repeat
    border-radius 8px
    margin-top 10px

.hint
    text-align center
    color #FFFFFF
    width 100%
    margin-top 20px

.order
    width 20px
    height 20px
    line-height 20px
    border-radius 10px
    text-align center
    background rgba(255, 221, 165, .5)
    color #E20709
    margin-right 8px

.mt-6
    margin-top 6px

.mb-12
    margin-bottom 12px

.mb-6
    margin-bottom 6px

.mr-10
    margin-right 10px

.icon-close
    position absolute
    right 30px
    top -60px
    width 36px
    height 36px
    z-index 100

.close-box
    display flex
    align-items center
    justify-content flex-end
    width 100%
    margin-top 100px

.inner-box
    height 100vh
    min-height 800px
    min-width: 900px;
    overflow: auto;
    box-sizing: border-box;
    display flex
    align-items center
    justify-content safe center
    flex-direction column
    margin-bottom 10px;

.inner-box::-webkit-scrollbar {
    display: none;
}

</style>
